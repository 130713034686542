import * as React from "react"
import { COMPONENT_DELAY_MS } from "../../helpers/constants"

const Breadcrumb = ({
  leftText,
  rightText,
  className,
  hideBorder
}) => {

  const breadcrumbRef = React.useRef()

  React.useEffect(() => {
    setTimeout(() => {

    }, COMPONENT_DELAY_MS)
  }, [])

  return (
    <div className={`${className}`}>
      <div ref={breadcrumbRef} className={`text-ms lg:text-xs pb-2 flex justify-between font-gotham font-bold uppercase overflow-hidden`}>
        <div className="breadcrumb-inner">{leftText}</div>
        <div className="breadcrumb-inner">{rightText}</div>
      </div>
      {
        !hideBorder && <hr/>
      }
      
    </div>
  )
}

export default Breadcrumb