import { window } from 'browser-monads'

const resolveConfig = require("tailwindcss/resolveConfig");
const tailwindConfig = require("../../tailwind.config.js");
const fullConfig = resolveConfig(tailwindConfig);

const isMobileQuery = {
  query: `(max-width: ${fullConfig.theme.screens.lg})`
}

const isDesktopQuery = {
  query: `(min-width: ${fullConfig.theme.screens.lg})`
}

const isMobile = () => window.innerWidth < 1023
const isDesktop = () => window.innerWidth >= 1024

export default { isMobileQuery, isMobile, isDesktopQuery, isDesktop }