import React, { useState } from "react"
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { window } from "browser-monads";

const LinkCopier = ({children}) => {
  const initialMessage = 'Copy link to clipboard.';
  const [message, setMessage] = useState(initialMessage);

  return (
    <Tippy 
      content={message}
      hideOnClick={false}
      offset={[0, 20]}
      theme="compassion"
      onHidden={() => setMessage(initialMessage)}
    >
     <div 
        className="cursor-pointer" 
        onClick={() => {
          navigator.clipboard.writeText(window.location.toString());
          setMessage('Link copied.');
        }}
      >
        {children}
      </div>  
    </Tippy>
  );
}

export default LinkCopier