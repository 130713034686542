import * as React from "react"
import { COMPONENT_DELAY_MS } from "../../helpers/constants"
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, FacebookMessengerShareButton } from "react-share"
import LinkCopier from "../../components/ui/link-copier"
import facebookIconBlack from "../../images/Social/facebook-icon-black.svg"
import linkedInIconBlack from "../../images/Social/linked-in-icon-black.svg"
import messengerIconBlack from "../../images/Social/messenger-icon-black.svg"
import twitterIconBlack from "../../images/Social/twitter-icon-black.svg"
import copyLinkIconBlack from "../../images/Social/copy-link-icon-black.svg"
import facebookIconSand from "../../images/Social/facebook-icon-sand.svg"
import linkedInIconSand from "../../images/Social/linked-in-icon-sand.svg"
import messengerIconSand from "../../images/Social/messenger-icon-sand.svg"
import twitterIconSand from "../../images/Social/twitter-icon-sand.svg"
import copyLinkIconSand from "../../images/Social/copy-link-icon-sand.svg"
import utils from "../../helpers/utils"
import { window } from "browser-monads"

const Share = ({
  isDark,
  url,
  title
}) => {
  const elRef = React.useRef()

  React.useEffect(() => {
    setTimeout(() => {

    }, COMPONENT_DELAY_MS)
  }, [])

  return (
    <div ref={elRef}>
      <h3 className="share-title | text-xs uppercase font-bold font-gotham pb-4" > Share</h3 >
      <div className={`social-icons inline-flex social-icons space-x-4 lg:space-x-2`}>
        <FacebookShareButton url={url} quote={title} hashtag="#restorehopeappeal">
          <img src={isDark ? facebookIconBlack : facebookIconSand} alt="Facebook icon" />
        </FacebookShareButton>
        <LinkedinShareButton title={title} url={url}>
          <img src={isDark ? linkedInIconBlack : linkedInIconSand} alt="LinkedIn icon" />
        </LinkedinShareButton>
        {
          window.matchMedia(`(min-width: ${utils.getSmallScreen()})`).matches ? <FacebookMessengerShareButton url={url} appId="710036283245936">
            <img src={isDark ? messengerIconBlack : messengerIconSand} alt="Messenger icon" />
          </FacebookMessengerShareButton> : <button onClick={() => {
            window.open(
              "fb-messenger://share?link=" +
              encodeURIComponent(url) +
              "&app_id=" +
              encodeURIComponent("710036283245936")
            );
          }}>
            <img src={isDark ? messengerIconBlack : messengerIconSand} alt="Messenger icon" />
          </button>
        }
        <TwitterShareButton url={url} title={title} hashtags={["#togetherweriseasone"]}>
          <img src={isDark ? twitterIconBlack : twitterIconSand} alt="Twitter icon" />
        </TwitterShareButton>
        <LinkCopier><img className="button" src={isDark ? copyLinkIconBlack : copyLinkIconSand} alt="Copy link icon" /></LinkCopier>
      </div>
    </div>
  )
}

export default Share


